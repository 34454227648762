






























































import { Vue, Component } from 'vue-property-decorator';
import DashboardApi from '@/services/dashboard';
import { DashboardConfiguration } from '@/models/dashboard';
import store from '@/store';

@Component
export default class ListVariables extends Vue {
  dashboardApi = new DashboardApi();

  dashboard: DashboardConfiguration | null = null;

  variableToDelete: number | null = null;

  variableFields: { text: string, value: string }[] = [
    { text: 'name', value: 'name' },
    { text: 'label', value: 'label' },
    { text: '', value: '' },
  ];

  isModalDeleteVariableOpen = false;

  created(): void {
    this.loadVariables();
  }

  loadVariables(): void {
    store.dispatch('setLoading', true);

    const dashboardId = parseInt(this.$route.params.id, 10);

    this.dashboardApi.findOne(dashboardId, false).then((response) => {
      if (response.success) {
        this.dashboard = response.result;
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
    }).finally(() => {
      store.dispatch('setLoading', false);
    });
  }

  showDeleteVariableModal(id: number) {
    this.variableToDelete = id;
    this.isModalDeleteVariableOpen = true;
  }

  // TODO: IMPORTANT (!), remove also associated panel if existing
  deleteVariable(): void {
    store.dispatch('setLoading', true);

    this.dashboard = {
      ...this.dashboard!,
      variables: this.dashboard!.variables!.filter((x) => x.id !== this.variableToDelete),
    };

    this.dashboardApi.update(this.dashboard!.id, this.dashboard).then((response) => {
      if (response.success) {
        this.isModalDeleteVariableOpen = false;
        this.loadVariables();
      } else {
        console.log('err', response);
        store.dispatch('setLoading', false);
      }
    }).catch((err) => {
      console.log('err');
      store.dispatch('setLoading', false);
    });
  }
}
